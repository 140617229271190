.search-block {
  width: 100%;
  display: flex;

  input {
    width: 100%;
    background-color: transparent;
    padding: 7px 10px;
    border: none;
    border-bottom: 1px solid;

    &:focus {
      border: none;
      border-bottom: 1px solid;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.padding-10 {
  padding: 10px;
}

.left-m-block {
  padding: 20px 20px;
  min-height: calc(100vh - 70px);
  box-shadow: 2px -3px 4.744px rgb(0 0 0 / 25%);
}

.typo-h2 {
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  letter-spacing: 0.036rem;
  margin-bottom: 0px;
}

.user-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;

  .img-block {
    height: 40px;
    width: 40px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  p {
    margin: 0px;
    font-size: 15px;
    line-height: 17px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
  }
}

.scroll-block {
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scroll-block::-webkit-scrollbar {
  display: none;
}

.left-userblock {
  height: calc(100vh - 200px);
}

.message-right {
  min-height: calc(100vh - 70px);

  .right-top {
    padding: 10px 0px 0px 0px;
    box-shadow: 2px 3px 4.744px rgb(0 0 0 / 25%);
  }
}

.right-bottom {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .search-block {
    position: relative;

    .smg {
      padding-right: 50px;
    }

    .fileupload {
      position: absolute;
      right: 22px;
      top: 50%;
      transform: translate(0, -50%);
      color: transparent;
      width: 40px;
      height: 40px;
      padding: 0;

      &::-webkit-file-upload-button {
        visibility: hidden;
      }

      &::before {
        content: '';
        display: flex;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        height: 40px;
        width: 40px;
        background: url('/upload.svg');
        background-size: contain;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        -o-background-size: contain;
        background-position: 100%;
        background-repeat: no-repeat;
      }

      &:active {
        outline: 0;
      }
    }
  }
}

.bodySms-show {
  padding: 15px;
  height: calc(100vh - 215px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.bodySms-show::-webkit-scrollbar {
  display: none;
}

@mixin smsText {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.035rem;
  color: #000000;
  padding: 4px 8px;
}

.reciver-block {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  p {
    margin: 10px 0px;
    @include smsText();
    text-align: left;
    width: 45%;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
  }

  span {
    margin-top: 4px;
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    padding: 3px;
    font-size: 12px;
    line-height: 16px;
    width: 50%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    cursor: pointer;
  }
}

.sender-block {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;

  p {
    margin: 10px 0px;
    @include smsText();
    text-align: right;
    width: 45%;
    border: 1px solid #cfcfcf;
    background: #38d4fc;
    border-radius: 4px;
  }

  span {
    margin-top: 4px;
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    padding: 3px;
    font-size: 12px;
    line-height: 16px;
    width: 50%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.no-data-found {
  text-align: center;
  color: red;
}

.profile_name {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  font-family: roboto, arial;
  color: white;
  line-height: 40px;
  text-align: center;
  background: orange;

  /*Align div in centre of page*/
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

// file show
.file_upload {
  position: relative;
  .show_file_name {
    position: absolute;
    right: 0px;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    bottom: 30px;
    border: 1px solid;
    border-radius: 5px;
    padding: 6px;
    justify-content: space-between;
    gap: 5px;
    background: #fff;
    label {
      font-size: 10px;
      line-height: 12px;
      color: #000000;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      width: 100%;
    }
    span {
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.sms_image {
  width: 100%;
  max-height: 250px;
  object-fit: contain;
}
