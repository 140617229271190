#versionIndicator {
    position: fixed;
    bottom: 5px;
    right: 10px;
    font-size: 12px;
    color: #7052fb;
}

h2.title {
    font-size: 2rem;
    font-weight: 600;
    padding: 20px 0;
    text-transform: capitalize;
}
h3.subtitle {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 20px 0;
    text-transform: capitalize;
}

.pie-chart {
    width: 50%;
    margin: auto;
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.number {
    font-size: 40px !important;
    font-weight: 500 !important;
    padding: 5px;
}
.block {
    width: 100%;
    height: 250px;
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-message {
    border-radius: 5px;
    border: 1px solid;
    padding: 5%;
    font-size: 16px;
}
.info-message.success {
    border-color: #12bf7c;
    background-color: #12bf7c;
    color: #fff;
}

.info-message.error {
    border-color: #ff000075;
    background-color: #ff000065;
    color: #fff;
}
.positive {
    color:#12bf7c !important;
    border-color:#12bf7c !important;
}
.negative {
    color: #ff000075 !important;
    border-color: #ff000075 !important;
}
.positive-text {
    color:#12bf7c !important;
}
.negative-text {
    color: #ff000075 !important;
}
.card {
    padding: 15px;
    width: 100%;
    box-shadow: 1px 0px 20px 5px #7052fb10 !important;
}

.align-corner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toolbar {
    padding-left: 50px;
}
.circle {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #fff;
    border:double;
    border-width: 5px;
    border-color: #fff;
    box-shadow: 0px 0px 0px 2px #fffdfd, inset 0px 0px 5px 0px #e0e0e0;
}

.two-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 5px;
}