.custom_table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.custom_header {
  display: flex;
  border: 1px solid #000000;
}

.custom_header .p-block {
  font-weight: bold;
}

.custom_fist_p {
  border-right: 1px solid #000000;
  flex-grow: 1;
}

.p-block {
  margin: 0px;
  padding: 5px 10px;
  width: 100%;
  word-break: break-all;
  text-align: center;
}

.custom_row {
  display: flex;
  border: 1px solid #000000;
  border-top: unset;
}

.created_block {
  width: 30%;
}

.one_line_text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.one_line_text:hover {
  display: flex;
  justify-content: center;
}
